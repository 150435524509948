import type {LoaderFunctionArgs} from '@remix-run/server-runtime';
import {json} from '@remix-run/server-runtime';
import {useLoaderData} from '@remix-run/react';

import {ColorSchemeEnum} from '@/enums';
import Disclaimers from '@/components/brochureV2/section/Disclaimers/Disclaimers';
import FAQ from '@/components/brochureV2/section/FAQ/FAQ';
import Image, {ImageVariant} from '@/components/brochureV2/section/Image';
import Outcomes from '@/components/brochureV2/section/Outcomes';
import SequencedContent from '@/components/brochureV2/section/SequencedContent/SequencedContent';
import {useTranslations} from '@/hooks/useTranslations';
import {
  isRetailSalesAvailable,
  isRetailPosTerminalAvailable,
  isRetailHardwareAndPaymentsAvailable,
} from '@/pages/shopify.com/($locale)/pos/posLocaleRules';
import {handle as parentHandle} from '@/pages/shopify.com/($locale)/pos/route';
import type {Handle} from '@/types';
import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import Conversion from '@/components/base/sections/Conversion/Conversion';
import {fetchTableData} from '@/utils/server/loader/plans/planFeaturesLoader.server';
import {pageLoader} from '@/utils/server/loader/pageLoader';
import {normalizeServerTableData} from '@/components/shared/Plans/FeaturesTable/utils/normalizeServerTableData';
import {fetchPlanSummaries} from '@/utils/server/loader/plans/planSummariesLoader.server';
import {normalizeServerPlanSummaryData} from '@/components/shared/Plans/FeaturesTable/utils/normalizeServerPlanSummaryData';
import {retailSpRateIncentiveDetails} from '@/pages/shopify.com/($locale)/pos/retailSpRateIncentiveDetails';
import {useRetailIncentive} from '@/hooks/experiments/useRetailIncentive';
import {useRetailUnifiedFunnel} from '@/hooks/experiments/useRetailUnifiedFunnel';
import type {PricingTablePlanData} from '@/components/shared/Plans/FeaturesTable/utils/normalizePlanTypes';

import PosHeroFloatingImage from '../components/Hero/PosHeroFloatingImage';

export const loader = async (args: LoaderFunctionArgs) => {
  const data = await pageLoader(args);
  const settingsJson = await args.context.getPageData('settings.json');
  const settings = JSON.parse(settingsJson ?? '{}');
  // t function (line 46) returns a string no matter what you value you pass through. Created a type to fit the shape of our SequencedContent object and passed the type through along with the returnObjects arg so the data remains an object.
  type Contents = {
    contents: {
      kicker: string;
      heading: string;
      blocks: {
        heading: string;
        contentHtml: string;
      }[];
    }[];
  };
  const contentsTranslations = args.context.i18n.t<
    'pages/pos/features/content:sequencedContent',
    {returnObjects: true},
    Contents
  >('pages/pos/features/content:sequencedContent');

  const {table} = await fetchTableData(args);
  const unformattedPlanSummaries = await fetchPlanSummaries(args);
  const summaries = normalizeServerPlanSummaryData(
    unformattedPlanSummaries.planSummaries,
  );
  return json({
    ...data,
    settings,
    table: normalizeServerTableData(table, summaries),
    planSummaries: summaries,
    contentsTranslations,
  });
};

export const handle: Handle = {
  ...parentHandle,
  metadata: {
    pageCategory: 'Sell',
    pageGroup: 'product',
    pagePath: '/pos/features',
  },
};

export default function PosFeatures() {
  const loaderData = useLoaderData<typeof loader>();
  const {isSPRateIncentive: isSPRateIncentiveVariant} = useRetailIncentive();
  const serverPlansData = loaderData.planSummaries || undefined;
  const retailSpRateIncentive = retailSpRateIncentiveDetails(serverPlansData);
  const {t, images, site, localeImage, values} = useTranslations();
  const {isRetailUnifiedFunnelExperiment} = useRetailUnifiedFunnel();
  const posFeaturesInterpolations = () => {
    let rates: Array<String> = [];

    const swipeRates = loaderData.table?.tableData?.find((feature) => {
      if ('type' in feature) return feature.type === 'swipe_rate';
      return false;
    }) as PricingTablePlanData | undefined;

    if (swipeRates?.plans) {
      rates = Object.keys(swipeRates.plans)
        .reverse()
        .map(
          (planName) =>
            `${loaderData.table?.planMap[planName]}: ${swipeRates.plans[planName]}`,
        );
    }

    const displayRates: string = rates.join(', ');

    const data = JSON.stringify(t('sequencedContent')).replaceAll(
      '{planRates}',
      displayRates,
    );

    return JSON.parse(data).contents;
  };

  const posRemoveShopifyPayments = () => {
    return loaderData.contentsTranslations.contents.filter((item) => {
      // @ts-expect-error - This key isn't in the Contents type. Removing will cause errors.
      return item['shopify-payments'] === undefined;
    });
  };

  return (
    <>
      <PosHeroFloatingImage
        className="[&_p.text-eyebrow-light-text]:text-lime-70 hover:[&_a.disclaimerLink]:text-black"
        colorScheme={ColorSchemeEnum.PosWarmGray}
        headingGroupProps={{
          size: 't1',
          kicker: t('hero.kicker'),
          headingHtml: t('hero.headingHtml'),
        }}
        images={[
          {
            alt: isRetailPosTerminalAvailable(site.locale)
              ? t('hero.image.alt.terminal')
              : t('hero.image.alt.default'),
            src: localeImage(images.hero.image),
          },
        ]}
        displayPromoBanner={false}
        ctas={{
          buttons: [
            {
              text: t('hero.link.text'),
              href: values.deeplinkFreeTrial,
            },
            ...(isRetailSalesAvailable(site.locale)
              ? [
                  {
                    text: t('hero.secondaryLink.text'),
                    href: '#contact-sales',
                  },
                ]
              : []), // Conditional rendering of secondary cta
          ],
          disclaimerHtml: t('hero.signup.disclaimerHtml'),
        }}
        incentiveDetails={
          isSPRateIncentiveVariant ? retailSpRateIncentive : null
        }
      />
      <Section topSpacing="2xl" className="pb-0">
        <SectionHeader {...t('sectionHeader2')} />
      </Section>
      <SequencedContent
        contents={
          isRetailHardwareAndPaymentsAvailable(site.locale)
            ? posFeaturesInterpolations()
            : posRemoveShopifyPayments()
        }
        blockHeadingClass="[&_span]:ml-2 [&_span]:py-1 [&_span]:px-2 [&_span]:uppercase [&_span]:text-xs [&_span]:rounded [&_span]:bg-banana-30"
        className="[&_li.border-shade-50]:border-shade-30"
      />
      <Disclaimers {...t('disclaimers')} />
      {isRetailPosTerminalAvailable(site.locale) && (
        <>
          <Section topSpacing="2xl" className="bg-pos-warm-gray pb-4">
            <SectionHeader {...t('sectionHeader')} />
          </Section>
          <Image
            {...t('image')}
            image={{
              alt: t('image.outcomes.alt.default'),
              srcSet: localeImage(images.outcomes.image),
            }}
            imageVariant={ImageVariant.V02}
            className='bg-pos-warm-gray [&_[data-spacer-size="2xl"]:last-child]:h-10'
          />
          <Outcomes
            {...t('outcomes')}
            className="bg-pos-warm-gray [&_div.h-3xl:first-of-type]:h-8"
          />
        </>
      )}
      <FAQ className="[&_h3_span.text-left]:text-t5" {...t('faq')} />
      <Section className="bg-lime-10">
        <Conversion
          headingHtml={t('conversion.headingHtml')}
          subheadHtml={t('conversion.subheadHtml')}
          buttons={[
            isRetailUnifiedFunnelExperiment
              ? {
                  text: t('pos:components.contactSalesCta'),
                  href: '#contact-sales',
                }
              : {
                  text: t('conversion.buttonText'),
                  href: values.deeplinkFreeTrial,
                },
          ]}
          disclaimerHtml={t('conversion.disclaimerHtml')}
        />
      </Section>
    </>
  );
}
